import React from "react";

import "./Presentation.scss";

export interface IPresentationProps {
    onSelectedPresentation: (presentation: number) => void
    pilot: boolean,
    playlist: number[],
    initialTime: number,
    play: boolean,
    song: {
        time: number,
        total: number,
        index: number
    },
    files: string[]
}

export class Presentation extends React.Component<IPresentationProps, any> {

    synced: boolean = true;

    componentDidUpdate(prevProps: IPresentationProps) {
        if (!this.synced || (this.props.play && this.props.song.index != prevProps.song.index)) {
            this.playSound();
            this.synced = true;
        }
    }

    componentDidMount() {
        if (!this.props.pilot) {
            setTimeout(() => {
                this.synced = false;
            }, 1000)

        }
    }

    audio = React.createRef<HTMLAudioElement>();

    get strokeDash(): string {
        let stroke = (251.2 / 100) * this.percentage;
        return `${stroke},251.2`
    }

    get percentage() {
        return (this.props.song.time / this.props.song.total) * 100;
    }


    playSound = () => {
        if (this.audio != null && this.audio.current != null) {
            this.audio.current.src = this.props.files[this.props.song.index];
            this.audio.current.currentTime = this.props.song.time;
            this.audio.current.play();


        }
    }

    onStart = (index: number) => {
        if (this.props.pilot && this.props.song.index != index) {
            this.props.onSelectedPresentation(index);
        }

    }

    get header() {
        if (this.props.pilot) {
            return "Wybierz prezentację";
        } else {
            if (!this.props.play) {
                return "Czekaj na uruchomienie prezentacji";
            } else {
                return "Zapraszamy do słuchania"
            }

        }
    }

    addNewItem() {
        return new Date() > new Date('2023-07-16 20:00');
    }


    render() {
        return (<div className="presentation-selector">
            <div>
                <h3 className="mb-0" style={{ fontWeight: "bold" }}>{this.header}</h3>
            </div>
            <div className="box">
                <div className="box-container" onClick={() => this.onStart(0)}>
                    <div className="title">V Symfonia</div>
                    <div className="author mt-1">Ludwig Van Beethoven</div>
                    {this.props.song.index == 0 && <div className="progress-container">
                        <div className="progress-text">Postęp prezentacji</div>
                        <div className="progress">
                            <div className="progress-bar bg-custom" role="progressbar" style={{ width: `${this.percentage}%` }} ></div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="box">
                <div className="box-container" onClick={() => this.onStart(1)}>
                    <div className="title"> Rydwany Ognia</div>
                    <div className="author mt-1">Vangelis</div>
                    {this.props.song.index == 1 && <div className="progress-container">
                        <div className="progress-text">Postęp prezentacji</div>
                        <div className="progress">
                            <div className="progress-bar bg-custom" role="progressbar" style={{ width: `${this.percentage}%` }} ></div>
                        </div>
                    </div>}
                </div>
            </div>
            {this.addNewItem() && <div className="box">
                <div className="box-container" onClick={() => this.onStart(2)}>
                    <div className="title"> Tonight</div>
                    <div className="author mt-1">Queen</div>
                    {this.props.song.index == 2 && <div className="progress-container">
                        <div className="progress-text">Postęp prezentacji</div>
                        <div className="progress">
                            <div className="progress-bar bg-custom" role="progressbar" style={{ width: `${this.percentage}%` }} ></div>
                        </div>
                    </div>}
                </div>
            </div>}
            <audio id="player" ref={this.audio}></audio>
        </div>)
    }
}

export default Presentation;