import React from "react";

import "./PresentationInProgress.scss";

interface IPresentationInProgress {
    onAcceptJoin: () => void;
}

export class PresentationInProgress extends React.Component<IPresentationInProgress, any> {
    render() {
        return (<div className="presentation-in-progress">
            <h3 className="text-center">Aktualnie trwa pokaz. Chcesz przyłączyć się do słuchania i oglądania?</h3>
            <button onClick={() => this.props.onAcceptJoin()} className="btn btn-primary mt-4">Tak, przyłącz mnie</button>
        </div>)
    }
}

export default PresentationInProgress;