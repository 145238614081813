import React from "react";
import { insidePolygon, Location } from 'geolocation-utils'

import "./DeviceOffline.scss";


export class DeviceOffline extends React.Component {

    render() {
        return (<div className="deviceoffline-view">
            <div className="text-center">
                <h3>Użyj ponownie kodu QR</h3>
            </div>
        </div>)
    }
}

export default DeviceOffline;