import { io, Socket } from "socket.io-client";

enum SWITCH { A, B }
enum SWTICH_STATE { OFF, ON }

interface StateChange { switch: SWITCH, state: SWTICH_STATE }

export class PilotService {

    socket: Socket

    constructor(socket: Socket) {
        this.socket = socket;

    }

    play(song: SWITCH) {
        if (this.socket.connected) {
            const statChange: StateChange = { state: SWTICH_STATE.ON, switch: song };
            this.socket.emit("play", statChange);
        }
    }
}
