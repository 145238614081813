import React from "react";
import { TopNavbar } from "../components/TopNavbar/TopNavbar";

export class Layout extends React.Component<any, any> {
    render() {
        return (
            <>
                <TopNavbar />
                <div className="app container-fluid">
                    {this.props.children}
                </div>
            </>)
    }
}

export default Layout;